import axios from '@axios'

const fetchSelections = queryParams => axios.get('/admin/selections', { params: queryParams })

const fetchSelection = id => axios.get(`/admin/selections/${id}`)

const storeSelection = data => axios.post('/admin/selections', data)

const storeSelectionChild = (id, data) => axios.post(`/admin/selections/${id}/children`, data)

const updateSelection = (id, data) => axios.put(`/admin/selections/${id}`, data)

const destroySelection = id => axios.delete(`/admin/selections/${id}`)

// === items ===

const getItems = params => axios.get('/admin/selection-items', { params })

const destroyItem = id => axios.delete(`/admin/selection-items/${id}`)

const addSelectionItem = (type, data) => axios.post(`/admin/selection-items/${type}`, data)

const updateSelectionItem = (id, data) => axios.put(`/admin/selection-items/${id}`, data)

export {
    addSelectionItem, destroyItem, destroySelection, fetchSelection, fetchSelections, getItems, storeSelection, storeSelectionChild, updateSelection, updateSelectionItem
}

