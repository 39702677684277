<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>
      </v-col>
    </v-row>

    <v-row v-if="selectionData">
      <v-col
        cols="8"
      >
        <v-card>
          <v-card-title>{{ t(selectionData.name) }}</v-card-title>

          <v-card-text>
            <p>
              Key:
              <b>{{ selectionData.key }}</b>
            </p>
            <p>
              Type:
              <b>{{ selectionData.type }}</b>
            </p>
            <p v-if="selectionData.distributor">
              Distributor:
              <v-chip v-if="selectionData.distributor">
                <b>{{ selectionData.distributor.slug }}</b>
              </v-chip>

              <router-link :to="{ name: 'selection-view', params: { id: selectionData.parent_id } }">
                See Parent
              </router-link>
            </p>
            <p v-if="selectionData.children.length">
              children:
              <router-link
                v-for="item in selectionData.children"
                :key="item.id"
                :to="{ name: 'selection-view', params: { id: item.id } }"
                class="mr-2"
              >
                {{ item.key }}
              </router-link>
            </p>
            <p>
              {{ selectionData.notes }}
            </p>
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title class="d-flex justify-space-between">
            <span>Items</span>
            <a @click="isItem = true">Add +</a>
          </v-card-title>

          <v-card-text v-if="selectionData">
            <SelectionItemsTable
              :selection="selectionData"
              @edit="item => { resource = item; isItem = true }"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="4"
      >
        <v-card class="mb-2">
          <v-card-title>Information</v-card-title>

          <v-card-text v-if="selectionData">
            <div class="d-flex justify-space-between">
              <span>Created At</span>
              <span>{{ selectionData.created_at | dateTime }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Actions</v-card-title>

          <v-card-text
            v-if="selectionData"
            class="d-flex justify-center"
          >
            <v-btn
              color="info"
              class="mr-2"
              @click="aside = true"
            >
              Edit
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <selection-aside
      v-if="aside"
      v-model="aside"
      :resource="selectionData"
      @changed="
        reloadSelection()
        aside = false
        resource = null
      "
    />

    <SelectionItemAside
      v-if="isItem"
      v-model="isItem"
      :resource="resource"
      :selection="selectionData"
      @changed="
        reloadSelection()
        isItem = false
        resource = null
      "
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import router from '@/router'
import { dateTime } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api'
import SelectionAside from './selection-resource/SelectionAside.vue'
import SelectionItemAside from './selection-resource/SelectionItemAside.vue'
import SelectionItemsTable from './selection-resource/SelectionItemsTable.vue'
import useSelectionView from './useSelectionView'

export default {
  components: {
    SelectionItemAside,
    SelectionAside,
    SelectionItemsTable,
  },

  filters: {
    dateTime,
  },

  watch: {
    '$route.params.id': 'reloadSelection',
  },

  setup() {
    const {
      getSelection, selectionData,
    } = useSelectionView()

    const aside = ref(false)
    const resource = ref(false)

    const reloadSelection = async () => {
      selectionData.value = null

      await getSelection(router.currentRoute.params.id)
    }

    onMounted(() => reloadSelection())

    const isItem = ref(false)

    return {
      aside,
      isItem,

      resource,

      selectionData,
      reloadSelection,
      t,

    }
  },
}
</script>

  <style lang="scss">
  .content {
    img {
      max-width: 100%;
    }
  }
  </style>
