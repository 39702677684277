import { fetchAllCategories, updateCategory } from '@/api/common'
import { useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useUsersList() {
  const categoryListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'KEY', value: 'key' },
    { text: 'NAME', value: 'name', sortable: false },
    { text: 'CODE', value: 'code' },
    { text: 'SUB CATEGORY', value: 'subcategory', sortable: false },
    { text: 'TYPE', value: 'type', sortable: false },
    { text: 'OPT SINGLE', value: 'opt_one', sortable: false },
    { text: 'SORT', value: 'sort' },
    { text: 'STATUS', value: 'status', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const filters = ref({
    search: '',
    distributor_id: null,
  })
  const totalListTable = ref(0)
  const loading = ref(false)
  const levelFilter = ref(cached[2] || 1)
  const levelOptions = ref([
    { title: 'Root (1)', value: 1 },
    { title: 'Main (2)', value: 2 },
    { title: 'Sub (3)', value: 3 },
  ])
  const optOneFilter = ref(cached[3] || undefined)
  const optOneOptions = ref([
    { title: 'Situable', value: 1 },
    { title: 'Unsituable', value: 0 },
  ])
  const totalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const loadCategories = (query = {}) =>
    fetchAllCategories(
      useTableOptions(options.value, {
        level: levelFilter.value,
        optOne: optOneFilter.value,
        ...filters.value,
        ...query,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        categoryListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false

        return records
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllCategories = async (updates = {}) => {
    loading.value = true
    for (const category of selectedRows.value) {
      const data = { ...updates }
      data.name = category.name
      await updateCategory(category.id, data)
    }
    loadCategories()
    loading.value = false
    selectedRows.value = []
  }

  const cachable = [options, filters, levelFilter, optOneFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadCategories()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    categoryListTable,
    tableColumns,
    filters,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadCategories,
    updateAllCategories,
    levelFilter,
    levelOptions,
    optOneFilter,
    optOneOptions,
  }
}
