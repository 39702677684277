var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.tableList,"options":_vm.options,"server-items-length":_vm.tableTotal,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemName(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}}),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.deleteItem(item).then(_vm.loadItems)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}}),_c('span',[_vm._v("Remove")])],1)],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.tableSelectedData),callback:function ($$v) {_vm.tableSelectedData=$$v},expression:"tableSelectedData"}})}
var staticRenderFns = []

export { render, staticRenderFns }