<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Name"
          class="mt-5"
        />

        <v-text-field
          v-model="form.notes"
          label="Notes"
          outlined
        />

        <v-select
          v-model="form.type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          label="Select Type"
          outlined
          hide-details
          :disabled="!!resource"
          class="mb-5"
        ></v-select>

        <v-text-field
          v-model="form.key"
          label="Key"
          outlined
          :rules="[required]"
          class="mt-5"
          :disabled="!!resource"
        />

        <v-autocomplete
          v-model="form.channel_id"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          label="Select Channel"
          outlined
          hide-details
          :disabled="!!resource"
          class="mb-5"
        ></v-autocomplete>

        <v-autocomplete
          v-model="form.distributor_id"
          :items="distributorOptions"
          item-text="label"
          item-value="value"
          label="Distributor (Reference)"
          outlined
          hide-details
          disabled
          class="mb-5"
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useDistributor, useSortTitle, useTranslatable } from '@/composables'
import channelStore from '@/modules/channel/store'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    parent: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  watch: {
    'form.channel_id': 'listDistributors',
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const channelOptions = channelStore.state.channels
      .map(channel => ({ title: `${channel.code}`, value: channel.id }))
      .sort(useSortTitle)

    const typeOptions = [
      { title: 'Product', value: 'product' },
      { title: 'Category', value: 'category' },
    ]

    const initialForm = {
      ...field('key', ''),
      ...field('channel_id', props.parent.channel_id || null),
      ...field('distributor_id', props.parent.distributor_id || null),
      ...field('type', 'product'),
      ...field('notes', null),
      name: useTranslatable(props.resource, 'name'),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const { distributorOptions, fetchDistributors } = useDistributor()

    const listDistributors = () => fetchDistributors({
      channel_id: form.value.channel_id,
      take: 100,
    })

    if (form.value.channel_id) listDistributors()

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      channelOptions,
      typeOptions,
      distributorOptions,
      listDistributors,
    }
  },
}
</script>
