<template>
  <v-navigation-drawer
    :value="active"
    permanent
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app

    @input="(val) => $emit('aside', val)"
  >
    <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container>

    <selection-item-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :options="options"
      @submit="handleSubmit"
    >
      <v-text-field
        slot="search"
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
      >
      </v-text-field>
    </selection-item-form>
  </v-navigation-drawer>
</template>

<script>
import { t } from '@/plugins/i18n'
import { addSelectionItem, updateSelectionItem } from '@api/channel/selection'
import { mdiClose } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

import { useExtractErrors } from '@/composables'
import useCategoryList from '@/modules/common/views/category-list/useCategoryList'
import useTagList from '@/modules/common/views/tag-list/useTagList'
import useProductList from '@/modules/product/views/product-list/useProductList'
import { debounce } from 'lodash'
import SelectionItemForm from './SelectionItemForm.vue'

export default {
  components: { SelectionItemForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const searchQuery = ref(undefined)
    const take = 999
    const errors = ref([])
    const options = ref([])

    const { loadProducts } = useProductList()
    const { loadCategories } = useCategoryList()
    const { loadTags } = useTagList()

    const loadOptions = () => {
      switch (props.selection.type) {
        case 'category':
          loadCategories({ take, search: searchQuery.value }).then(records => {
            options.value = records.map(d => ({
              title: t(d.name),
              value: d.id,
            }))
          })
          break

        case 'tag':
          loadTags({ take, search: searchQuery.value }).then(records => {
            options.value = records.map(d => ({
              title: t(d.name),
              value: d.id,
            }))
          })
          break

        case 'product':
          loadProducts({ channel_id: props.selection.channel_id, take, search: searchQuery.value }).then(records => {
            options.value = records.map(d => ({
              title: t(d.title),
              value: d.id,
            }))
          })
          break

        default:
          break
      }
    }

    onMounted(loadOptions)

    watch(searchQuery, debounce(loadOptions, 300))

    const handleSubmit = async form => {
      loading.value = true
      const addType = props.selection.type.replace('_', '-') // article_tag -> article-tag
      const request = props.resource ? updateSelectionItem(props.resource.id, {
        ...form,
      }) : addSelectionItem(addType, {
        ...form,
        selection_id: props.selection.id,
      })

      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      searchQuery,
      loading,
      errors,
      options,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
